import React from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate} from 'react-router-dom'
import '@elastic/eui/dist/eui_theme_light.css'
import {useEffect} from 'react'

import {Provider} from 'eui'
import {ErrorPage, NavigatorPage, PageFinder, LoadingPage} from 'pages'
import { NavStore, SessionStore } from 'store'
import { RouteUtils } from 'utils'
import { NavError } from 'types'
import {PageApi, PageApiRedirect, PageEmailConfirmationSuccess, PageLogin,
    PageOrganisation,
    PageOrganisationRedirect,
    PagePasswordNew,
    PagePasswordReset,
    PageRow, PageSignup, PageTable,
    PageTableRedirect, PageUser, PageUserRedirect, PageVersion, PageWorkspace, PageWorkspaceRedirect } from 'Page'
import {FORMAT_PAGE_URLS} from 'Format'
import { ModulePageHeader } from 'Module'
import { GlobalViewNav } from 'Global'
import { ComponentNavPageRedirect } from 'Component'



const App = () => {

    return <Provider>
        <Router basename='/'>
            <AppRoutes/>
        </Router>
    </Provider>
}

const AppRoutes = () => {
    const isNav :boolean = GlobalViewNav(store => store.isNav)

    try {
        return (isNav) ? <Routes>
                <Route path={'/*'} element={<PageComponent isNav={isNav}/>}/>
            </Routes>
            :
            <Routes>
                <Route path={FORMAT_PAGE_URLS.userLogin} element={<PageLogin/>}/>
                <Route path={FORMAT_PAGE_URLS.userSignup} element={<PageSignup/>}/>
                <Route path={FORMAT_PAGE_URLS.userPasswordReset} element={<PagePasswordReset/>}/>
                <Route path={'/api/:apiId'} element={<PageApiRedirect/>}/>
                <Route path={'/api/:apiId/ws'} element={<PageApi/>}/>
                <Route path={'/org/:orgId'} element={<PageOrganisationRedirect/>}/>
                <Route path={'/org/:orgId/ws'} element={<PageOrganisation/>}/>
                <Route path={'/user/:userId'} element={<PageUserRedirect/>}/>
                <Route path={'/user/:userId/email-confirmation/:token'} element={<PageEmailConfirmationSuccess/>}/>
                <Route path={'/user/:userId/password-reset/:token'} element={<PagePasswordNew/>}/>
                <Route path={'/user/:userId/ws'} element={<PageUser/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws'} element={<PageWorkspaceRedirect/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table'} element={<PageWorkspace activeResourceKey={'table'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/iface'} element={<PageWorkspace activeResourceKey={'iface'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/responder'} element={<PageWorkspace activeResourceKey={'responder'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table'} element={<PageTableRedirect/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version'} element={<PageVersion/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row'} element={<PageTable/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/child/:childId/row'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/data'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/transform/:transform'} element={<ComponentNavPageRedirect navKey={'output'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/transform/:transform/output'} element={<PageRow/>}/>
                <Route path={'/*'} element={<PageComponent isNav={isNav}/>}/>
            </Routes>
    } catch (err) {
        return <ErrorPage/>
    }
}

type PropsPageComponent = {
    isNav: boolean
}


const PageComponent = ({ isNav } :PropsPageComponent) => {

    const location = useLocation()
    const navigate = useNavigate()

    const nav = NavStore(state => state.nav)
    const loading = NavStore(state => state.loading)
    const fetchNav = NavStore(state => state.fetchNav)
    const token = SessionStore(state => state.token)

    const locationPath = location.pathname + location.search
    const navPath = (nav) ? nav.path : locationPath

    const error :NavError | undefined = nav?.error

    const isPageStatic = () :boolean => RouteUtils.isStatic(locationPath)


    const doFetchNav = () => {
        fetchNav(isPageStatic() ? '/' : locationPath)
    }

    const doNavigate = (to :string) => {
        navigate(to)
    }

    useEffect(() => {
        if (!error && navPath !== locationPath && !isPageStatic()) {
            doNavigate(navPath)
        }
    }, [navPath])


    useEffect(() => {
        if (!loading && !nav) {
            doFetchNav()
        }
        if (!loading && locationPath !== navPath) {
            doFetchNav()
        }
    }, [locationPath])

    useEffect(() => {
        if (!loading && nav) {
            doFetchNav()
        }
    }, [token])

    useEffect(() => {
        if (isNav && isPageStatic()) navigate('/')
    }, [isNav])

    const nodeResponse = PageFinder({path: location.pathname})
    if (!error && !isNav && nav && nodeResponse.path !== location.pathname && !isPageStatic()) {
        doNavigate(nodeResponse.path)
        return null
    }

    if (!nav) return <LoadingPage/>

    if (nav?.error) return <ErrorPage nav={nav} token={token}/>

    return <>
        <ModulePageHeader hasBreadcrumbs={!isPageStatic()}/>
        { (isNav) ? <NavigatorPage/> : nodeResponse.render() }
    </>
}

export default App
