import {ButtonEmpty, FlexGroup, FlexItem, Icon, Page, PageBody, PageSection, Spacer, Tab, TabbedContent, Tabs, Title} from 'eui'

import Raw from './Raw/Raw'
import UserInterface from './UserInterface/UserInterface'
import { NavStore } from 'store'
import { BackButton, NavUrlDisplay } from 'components'
import {ReactNode, useState } from 'react'

type TabType = {
    id :'nav' | 'raw'
    name :string
    content :ReactNode
    prepend :ReactNode
}

const Nav = () => {

    const [active, setActive] = useState<'nav' | 'raw'>('nav')

    const nav = NavStore(state => state.nav)
    if (!nav) return null
    
    const tabs :TabType[] = [
        {
            id: 'nav',
            name: 'Navigator User Interface',
            content: <UserInterface/>,
            prepend: <Icon type={'accessibility'}/>
        },
        {
            id: 'raw',
            name: 'Raw API Response',
            content: <Raw/>,
            prepend: <Icon type={'compute'}/>
        }
    ]

    if (nav.error) return <div>{`Error. ${nav.error.message}`}</div>


    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <PageSection paddingSize={'none'}>
                <div style={{padding: '24px 24px 0px 24px', borderBottom: '1px solid #D3DAE6'}}>
                    <FlexGroup justifyContent={'spaceBetween'}>
                        <FlexItem grow={1}>
                            <Title size={'l'}>
                                <h1 style={{fontWeight: 500}}>Morph Cloud API Navigator</h1>
                            </Title>
                        </FlexItem>
                        <FlexItem grow={1}>
                            <NavUrlDisplay nav={nav}/>
                        </FlexItem>
                    </FlexGroup>
                    <Spacer size={'m'}/>
                    <div style={{marginBottom: '-1px'}}>
                        <Tabs>{tabs.map(t => <Tab key={t.id} prepend={t.prepend} isSelected={t.id === active} onClick={() => {setActive(t.id)}}>{t.name}</Tab>)}</Tabs>
                    </div>
                </div>
            </PageSection>
            {tabs.filter(t => t.id === active).map(t => t.content)[0]}
        </PageBody>
    </Page>

}

export default Nav