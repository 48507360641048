import {NavField} from 'components'
import {Button, ButtonEmpty,
    Callout, Form, LoadingSpinner, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle, Spacer } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Nav, NavControlAction, NavControlActionType, FieldOnChange, NavControlActionFormField, DataPayload, NavControlActionForm } from 'types'
import { FormUtils, MappingUtils, NavClient, RouteUtils } from 'utils'

type Props = {
    nav :Nav
    actionType :NavControlActionType
    title? :string
    onClose :() => void
    onSuccess? :(response: Nav, req? :DataPayload) => void
}

const NavActionModal = ({nav, actionType, title, onClose, onSuccess} :Props) => {
    const action :NavControlAction | undefined = nav.control.action[actionType]
    const form :NavControlActionForm | undefined = action && action.form
    const [data, setData] = useState<DataPayload>(FormUtils.defaultValues(action))
    const [formErrors, setFormErrors] = useState<{[key :string] :string }>({})
    const [sending, setSending] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const navigate = useNavigate()

    const onChange: FieldOnChange = (name: string, value: string | File | null) => {
        const updated :DataPayload = {...data}
        if (value !== null) {
            updated[name] = value
        } else {
            delete updated[name]
        }
        setData(updated)
    }

    const onSubmit = async () => {
        const responseNav :Nav = await NavClient.action(nav, actionType, data)
        const error = responseNav.error
        if (error) {
            setFormErrors({...error.fieldErrors})
            setErrorMessage(error.message)
        } else if (onSuccess) {
            onSuccess(responseNav, data)
        } else if (responseNav && responseNav.data.entity && responseNav.data.entity['linkTo']) {
            navigate(responseNav.data.entity['linkTo'])
        } else if (actionType === 'delete') {
            navigate(RouteUtils.back(responseNav.path))
        }
    }

    return <Modal onClose={onClose} style={{width: '600px'}}>
        <ModalHeader>
            <ModalHeaderTitle><h1>{title || actionType}</h1></ModalHeaderTitle>
        </ModalHeader>
        <ModalBody>
            <Form fullWidth>
                { (form && form.fields.map(field => {
                    const { name } = field
                    return <NavField key={name} nav={nav} value={data[name]} error={formErrors[name]} field={field} onChange={onChange}/>
                })) || []}
                { errorMessage && <><Spacer size={'m'}/><Callout title={errorMessage} color={'danger'} size={'s'} iconType={'faceSad'}/></> }
            </Form>
        </ModalBody>
        <ModalFooter>
            {(sending) ? <LoadingSpinner/> : null}
            <ButtonEmpty color={'danger'} onClick={onClose}>Cancel</ButtonEmpty>
            <Button fill onClick={onSubmit}>{MappingUtils.buttonTitle(actionType)}</Button>
        </ModalFooter>
    </Modal>
}

export default NavActionModal