import {Nav, PageSortDirection, Param, ParamUpdate, TableFilter } from 'types'
import {Location} from 'react-router-dom'
import UpdateParam from "../../types/ParamUpdate/ParamUpdate";
import { ClassPublicEnv } from 'Class';

const SEARCH_FILTER_PREFIX = 'filter-'


const getUrlStr = () => {
    return window.location.href
}

const getParam = <T = string>(param :Param) :T | null => {
    return new URL(window.location.href).searchParams.get(param) as T | null
}

const getParamFromNav = <T = string>(nav :Nav, param :Param) :T | null => {
    return new URL(ClassPublicEnv.getResult()?.httpUrl + nav.path).searchParams.get(param) as T | null
}

const clearParamForPath = (path :string) :string => {
    const urlObj = new URL(`${window.location.origin}${path}`)
    const keys :string[] = []
    for (const [key, value] of urlObj.searchParams.entries()) keys.push(key)
    for (let i = 0; i < keys.length; i++) urlObj.searchParams.delete(keys[i])
    return `${urlObj.pathname}${urlObj.search}`
}

const updateParamForPath = (path :string, params :ParamUpdate[]) :string => {
    const urlObj = new URL(`${window.location.origin}${path}`)
    for (let i = 0; i < params.length; i++) urlObj.searchParams.set(params[i].param, params[i].paramValue)
    return `${urlObj.pathname}${urlObj.search}`
}

const appendParamForPath = (path :string, params :ParamUpdate[]) :string => {
    const urlObj = new URL(`${window.location.origin}${path}`)
    for (let i = 0; i < params.length; i++) urlObj.searchParams.append(params[i].param, params[i].paramValue)
    return `${urlObj.pathname}${urlObj.search}`
}

const removeParameterFromPath = (path :string, toRemove :string) :string => {
    const urlObj = new URL(`${window.location.origin}${path}`)
    urlObj.searchParams.delete(toRemove)
    return `${urlObj.pathname}${urlObj.search}`
}

const removeParameterFromSearch = (search :string, toRemove :string) :string => {
    let params = new URLSearchParams(search)
    params.delete(toRemove)
    return params.toString()
}

const toNumber = (num :string | null) :number | null => {
    return (num !== null) ? parseInt(num) : null;
}
const getPageTableFilterParams = () :TableFilter[] => {
    const urlObj :URL = new URL(window.location.href)
    return getTableFilterParams(`${urlObj.pathname}${urlObj.search}`)
}

const getTableFilterParams = (path :string) :TableFilter[] => {
    const url :URL = new URL(`${window.location.origin}${path}`)
    const res :TableFilter[] = []
    const map :{[key :string] :boolean} = {}
    url.searchParams.forEach((value, key) => {
        if (key.includes(SEARCH_FILTER_PREFIX) && !map[key]) {
            res.push({
                param: key,
                type: 'equ',
                field: key.replace(SEARCH_FILTER_PREFIX, ''),
                values: url.searchParams.getAll(key)
            })
            map[key] = true
        }
    })
    return res
}

const buildFilterParamUpdate = (field :string, value :string) :UpdateParam => {
    return  {param: `filter-${field}`, paramValue: value}
}

const SearchParamUtils = {
    updateParamForLocation: (location :Location, params :ParamUpdate[]) :string => {
        return updateParamForPath(`${location.pathname}${location.search}`, params)
    },
    updateParamForPath,
    appendParamForPath,
    buildFilterParamUpdate,
    getSortDirection: () :PageSortDirection | null => {
        return getParam<PageSortDirection>('sortDirection')
    },
    getSortField: () :string | null => {
        return getParam('sortField')
    },
    getSize: () :number | null => {
        return toNumber(getParam('size'))
    },
    getPage: () :number | null => {
        return toNumber(getParam('page'))
    },
    getSearchKey: () :string | null => {
        return getParam<string>('searchKey')
    },
    getSearchValue: () :string | null => {
        return getParam<string>('searchValue')
    },
    getNavPage: (nav :Nav) :number | null => {
        return toNumber(getParamFromNav(nav, 'page'))
    },
    getNavSize: (nav :Nav) :number | null => {
        return toNumber(getParamFromNav(nav, 'size'))
    },
    getPageTableFilterParams: getPageTableFilterParams,
    getTableFilterParams: getTableFilterParams,
    removeParameterFromPath: removeParameterFromPath,
    removeParameterFromSearch: removeParameterFromSearch,
    clearParamForPath: clearParamForPath
}

export default SearchParamUtils