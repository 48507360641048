import { TablePagination } from 'eui'
import {Nav, Param, ParamUpdate } from 'types'
import { NavClient, SearchParamUtils } from 'utils'

type Props = {
    nav :Nav
    itemsPerPageOptions? :number[]
    setNewNav :(nav :Nav) => void
}

const NavTableNavPagination = ({nav, setNewNav, itemsPerPageOptions = [5, 10, 25, 50, 100]}:Props) => {

    const activePage :number | null = SearchParamUtils.getNavPage(nav)
    const itemsPerPage :number | null = SearchParamUtils.getNavSize(nav)

    if (activePage === null || itemsPerPage === null) return null

    const pageCount :number = (nav.data.page && nav.data.page.totalElements > 0) ? Math.ceil(nav.data.page.totalElements / itemsPerPage) : 0

    const updateParamAndNavigate = async (param :Param, paramValue :string) => {
        setNewNav(await NavClient.fetchNav(nav.path, param, paramValue))
    }

    const onChangeItemsPerPage = (pageSize :number) => {updateParamAndNavigate('size', String(pageSize))}

    const onChangePage = (pageIndex :number) => {updateParamAndNavigate('page', String(pageIndex))}

    return <TablePagination showPerPageOptions={true} itemsPerPage={itemsPerPage} activePage={activePage} pageCount={pageCount} onChangeItemsPerPage={onChangeItemsPerPage} onChangePage={onChangePage} itemsPerPageOptions={itemsPerPageOptions}/>
}

export default NavTableNavPagination