import {FlexGroup, FlexItem, TablePagination } from 'eui'
import { useLocation, useNavigate } from 'react-router-dom'
import {Param, ParamUpdate } from 'types'
import { SearchParamUtils } from 'utils'

type Props = {
    totalElements :number
    itemsPerPageOptions? :number[]
}

const NavTablePagination = ({totalElements, itemsPerPageOptions = [10, 25, 50, 100]}:Props) => {

    const location = useLocation()
    const navigate = useNavigate()

    const activePage :number | null = SearchParamUtils.getPage()
    const itemsPerPage :number | null = SearchParamUtils.getSize()

    if (activePage === null || itemsPerPage === null) return null

    const pageCount :number = Math.ceil(totalElements / itemsPerPage)

    const updateParamAndNavigate = (param :Param, paramValue :string) => {
        const toUrl :string = SearchParamUtils.updateParamForLocation(location, [{param: param, paramValue: paramValue}])
        navigate(toUrl)
    }

    const onChangeItemsPerPage = (pageSize :number) => {updateParamAndNavigate('size', String(pageSize))}

    const onChangePage = (pageIndex :number) => {updateParamAndNavigate('page', String(pageIndex))}

    return <TablePagination showPerPageOptions={true}
                             itemsPerPage={itemsPerPage}
                             activePage={activePage}
                             pageCount={pageCount}
                             onChangeItemsPerPage={onChangeItemsPerPage}
                             onChangePage={onChangePage}
                             itemsPerPageOptions={itemsPerPageOptions}/>
}

export default NavTablePagination

